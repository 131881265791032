import React from 'react';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import withTranslations from '../../components/hoc/withTranslations';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import DataEN from '../../locales/en/translation.json';

const NotFoundPage = () => {
  i18next.changeLanguage('en');
  i18next.init(
    {
      fallbackLng: 'en',
      resources: { en: { translation: DataEN } }
    },
    (err, t) => {
      if (err) return console.log('something went wrong loading', err);
      t('key'); // -> same as i18next.t
    }
  );

  const { i18n, t } = useTranslation(['DataEN']);

  return (
    <Layout>
      <Seo title='Error 404' />
      <h1>Error 404</h1>
      <p>The page you requested couldn't be found.</p>
    </Layout>
  );
};

export default withTranslations()(NotFoundPage);
